import React, { FC } from 'react';
import * as Styled from './RoundedNavigation.styles';
import * as prismicH from '@prismicio/helpers';
import { HomePrismicSliceRond } from '@modules/home/model';
import { getLinkPropsFromPrismicLink } from '@core/prismic';
import { isFilled } from '@prismicio/helpers';

interface RoundedNavigationProps {
  item: HomePrismicSliceRond;
}

const RoundedNavigation: FC<RoundedNavigationProps> = ({ item }) => (
  <Styled.RoundedNavigationContainer>
    <Styled.RoundedNavigationTitle>{prismicH.asText(item.primary.title)}</Styled.RoundedNavigationTitle>

    <Styled.RoundedNavigationLinks>
      {item.items.map((link, index) => (
        <Styled.RoundedNavigationLink {...getLinkPropsFromPrismicLink(link.link)} key={index}>
          {isFilled.image(link.logo) ? (
            <Styled.RoundedNavigationImage image={link.logo} imgixParams={{ ar: 1, fit: 'fill', fill: 'solid' }} />
          ) : null}
        </Styled.RoundedNavigationLink>
      ))}
    </Styled.RoundedNavigationLinks>
  </Styled.RoundedNavigationContainer>
);

export default RoundedNavigation;
