import styled from 'styled-components';
import { complementaryColor, fontSize, fontWeight, primaryColor, tertiaryColor } from '@styles/utils';
import { RichText } from '@core/prismic';

export const NavigationSimpleContainer = styled.div`
  margin: 0 auto;
  padding-top: 64px;
  width: 100%;
  max-width: 1090px;

  @media screen and (max-width: 1230px) {
    padding: 50px 20px;
  }
`;

export const NavigationSimpleTitle = styled.p`
  ${fontSize.h3};
  font-weight: ${fontWeight.bold};
  color: ${tertiaryColor(700)};
`;

export const NavigationSimpleContent = styled.div`
  padding-top: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 40px;

  @media screen and (max-width: 1230px) {
    grid-template-columns: 1fr;
    row-gap: 16px;
  }
`;

export const NavigationSimpleItem = styled.div`
  padding: 20px 24px;
  background-color: ${complementaryColor('white')};
`;

export const NavigationSimpleItemTitle = styled.p`
  padding-bottom: 12px;
  ${fontSize.h4};
  font-weight: ${fontWeight.semiBold};
  color: ${tertiaryColor(700)};
  border-bottom: solid 1px rgba(112, 112, 112, 0.25);
`;

export const NavigationSimpleItemList = styled(RichText)`
  padding-top: 16px;

  li {
    ${fontSize.standard};
    color: ${primaryColor(400)};
    padding-bottom: 15px;
  }
`;
