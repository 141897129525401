import styled from 'styled-components';
import { fontSize, fontWeight, tertiaryColor } from '@styles/utils';
import { Button } from '@styles/shared/buttons';

export const NavigationContainer = styled.div`
  padding: 25px 20px;
  background-color: ${tertiaryColor(100)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const NavigationTitle = styled.p`
  padding-bottom: 15px;
  font-size: 22px;
  font-weight: ${fontWeight.semiBold};
  color: ${tertiaryColor(700)};
  line-height: 1.32;
`;

export const NavigationDescription = styled.p`
  ${fontSize.standard};
  color: ${tertiaryColor(700)};
`;

export const NavigationLink = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const NavigationButton = styled(Button)`
  width: 47px;
  height: 47px;
`;
