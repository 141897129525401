import React from 'react';
import { getPageInitialProps, HttpResult } from '@core/http';
import { getPrismicDocumentByType, PrismicDocument } from '@core/prismic';
import { renderHttpResult } from '@shared/utils/render';
import { NextPageWithLayout } from 'next';
import Home from '@modules/home/Home';
import { HomePrismicContent } from '@modules/home/model';

const HomePage: NextPageWithLayout<HttpResult<PrismicDocument<HomePrismicContent>>> = res =>
  renderHttpResult(res, document => <Home document={document} />);

HomePage.getLayoutProps = () => ({ headerLight: true });

HomePage.getInitialProps = getPageInitialProps(getPrismicDocumentByType('home'));

export default HomePage;
