import React, { FC } from 'react';
import * as Styled from './Home.styles';
import * as prismicH from '@prismicio/helpers';
import { getSeoFromPrismicDocument, PrismicDocument } from '@core/prismic';
import { HomePrismicContent, HomePrismicContentSlices } from '@modules/home/model';
import Seo from '@shared/components/seo/Seo';
import RoundedNavigation from '@modules/home/components/rounded-navigation/RoundedNavigation';
import Reseller from '@modules/home/components/reseller/Reseller';
import NavigationImage from '@modules/home/components/image/NavigationImage';
import NavigationText from '@modules/home/components/text/NavigationText';
import NavigationSimple from '@modules/home/components/simple/NavigationSimple';
import illuHeader from '@assets/icons/illu-header.svg';

interface HomeProps {
  document: PrismicDocument<HomePrismicContent>;
}

const Home: FC<HomeProps> = ({ document }) => {
  const render = (item: HomePrismicContentSlices, i: number) => {
    switch (item.slice_type) {
      case 'navigation_rond':
        return <RoundedNavigation item={item} key={i} />;
      case 'revendeur':
        return <Reseller item={item} key={i} />;
      case 'navigation_image':
        return <NavigationImage item={item} key={i} />;
      case 'navigation_texte':
        return <NavigationText item={item} key={i} />;
      case 'navigation_simple':
        return <NavigationSimple item={item} key={i} />;
      default:
        return;
    }
  };

  return (
    <>
      <Seo {...getSeoFromPrismicDocument(document)} noIndex={false} canonical="home" />

      <Styled.HomeHeaderContainer>
        <Styled.HomeHeaderContent>
          <Styled.HomeHeaderContentTextContainer>
            <Styled.HomeHeaderContentText>
              <Styled.HomeHeaderContentTitle>{prismicH.asText(document.data.title)}</Styled.HomeHeaderContentTitle>

              <Styled.HomeHeaderContentSubtitle>{document.data.subtitle}</Styled.HomeHeaderContentSubtitle>

              <Styled.HomeHeaderContentSearch />
            </Styled.HomeHeaderContentText>
          </Styled.HomeHeaderContentTextContainer>

          <Styled.HomeHeaderContentImage src={illuHeader.src} alt="illu" />
        </Styled.HomeHeaderContent>
      </Styled.HomeHeaderContainer>

      {document.data.slices.map((item, i) => render(item, i))}
    </>
  );
};

export default Home;
