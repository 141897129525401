import React, { FC } from 'react';
import * as Styled from './NavigationText.styles';
import * as prismicH from '@prismicio/helpers';
import { HomePrismicSliceText } from '@modules/home/model';
import Navigation from '@shared/components/navigation/Navigation';

interface NavigationTextProps {
  item: HomePrismicSliceText;
}

const NavigationText: FC<NavigationTextProps> = ({ item }) => {
  const { primary, items } = item;
  return (
    <Styled.NavigationTextWrapper>
      <Styled.NavigationTextContainer>
        <Styled.NavigationTextTitle>{prismicH.asText(primary.title)}</Styled.NavigationTextTitle>

        <Styled.NavigationTextContent>
          {items.map((it, index) => (
            <Navigation description={it.description} link={it.link} key={index} />
          ))}
        </Styled.NavigationTextContent>
      </Styled.NavigationTextContainer>
    </Styled.NavigationTextWrapper>
  );
};

export default NavigationText;
