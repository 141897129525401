import styled from 'styled-components';
import backgroundIcon from '@assets/icons/background.svg';
import { fontSize, fontWeight, mediaMax, tertiaryColor } from '@styles/utils';
import SearchInput from '@modules/search/components/input/SearchInput';

export const HomeHeaderContainer = styled.div`
  background-image: linear-gradient(to left, #0b654c 30%, #2662a0 100%);

  @media screen and (${mediaMax.tablet}) {
    background-image: linear-gradient(to top, #0b654c 20%, #2662a0 100%);
  }
`;

export const HomeHeaderContent = styled.div`
  position: relative;
  padding: 104px 0 64px 0;
  display: flex;
  justify-content: flex-start;
  background: url(${backgroundIcon.src}) no-repeat center center;
  background-size: cover;

  @media screen and (max-width: 1230px) {
    padding: 125px 20px 50px 20px;
  }
`;

export const HomeHeaderContentTextContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1090px;

  @media screen and (max-width: 1600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const HomeHeaderContentText = styled.div`
  width: 100%;
  max-width: 600px;

  @media screen and (max-width: 1600px) {
    max-width: unset;
  }
`;

export const HomeHeaderContentTitle = styled.p`
  ${fontSize.h1};
  font-weight: ${fontWeight.bold};
  color: ${tertiaryColor(100)};
  line-height: 1.24;
`;

export const HomeHeaderContentSubtitle = styled.p`
  padding-top: 15px;
  ${fontSize.h4};
  color: ${tertiaryColor(100)};
  line-height: 1.45;
`;

export const HomeHeaderContentSearch = styled(SearchInput)`
  margin-top: 30px;
  height: 60px;
  width: 500px;
  max-width: 100%;
`;

export const HomeHeaderContentImage = styled.img`
  position: absolute;
  bottom: -72px;
  right: 15%;
  width: 100%;
  max-width: 395px;

  @media screen and (max-width: 1600px) {
    display: none;
  }
`;
