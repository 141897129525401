import styled from 'styled-components';
import { fontSize, fontWeight, mediaMax, tertiaryColor } from '@styles/utils';
import { Button } from '@styles/shared/buttons';

export const NavigationSimpleContainer = styled.div`
  margin: 0 auto;
  padding-top: 64px;
  width: 100%;
  max-width: 1090px;

  @media screen and (max-width: 1230px) {
    padding: 50px 20px;
  }
`;

export const NavigationSimpleTitle = styled.p`
  ${fontSize.h3};
  font-weight: ${fontWeight.bold};
  color: ${tertiaryColor(700)};
`;

export const NavigationSimpleContent = styled.div`
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 28px;

  @media screen and (max-width: 1230px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
  }

  @media screen and (${mediaMax.mobile}) {
    grid-template-columns: 1fr;
    row-gap: 30px;
  }
`;

export const NavigationSimpleItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const NavigationSimpleDescription = styled.p`
  padding: 16px 0;
  ${fontSize.standard};
  color: ${tertiaryColor(700)};
`;

export const NavigationSimpleButton = styled(Button)`
  width: max-content;
`;
