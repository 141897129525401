import styled from 'styled-components';
import { rgba } from 'polished';
import { fontSize, fontWeight, tertiaryColor } from '@styles/utils';
import CustomLink from '@shared/components/link/CustomLink';
import Image from '@core/prismic/components/Image';

export const RoundedNavigationContainer = styled.div`
  padding-top: 64px;
  margin: 0 auto;
  width: 100%;
  max-width: 1090px;

  @media screen and (max-width: 1230px) {
    padding: 50px 20px;
  }
`;

export const RoundedNavigationTitle = styled.p`
  ${fontSize.h3};
  font-weight: ${fontWeight.bold};
  color: ${tertiaryColor(700)};
`;

export const RoundedNavigationLinks = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const RoundedNavigationLink = styled(CustomLink)`
  margin: 16px 14px;
  padding: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 152px;
  height: 152px;
  border-radius: 95px;
  box-shadow: 5px 10px 20px 0 ${props => rgba(tertiaryColor(900)(props), 0.08)};
  background-color: ${tertiaryColor(100)};
  cursor: pointer;

  @media screen and (max-width: 500px) {
    margin: 15px 10px;
  }

  @media screen and (max-width: 460px) {
    width: 130px;
    height: 130px;
  }
`;

export const RoundedNavigationImage = styled(Image)`
  display: inline-flex;
`;
