import React, { FC } from 'react';
import * as Styled from './NavigationImage.styles';
import * as prismicH from '@prismicio/helpers';
import { HomePrismicSliceImage } from '@modules/home/model';
import Image from '@core/prismic/components/Image';
import { isFilled } from '@prismicio/helpers';
import { getLinkPropsFromPrismicLink } from '@core/prismic';
import CustomLink from '@shared/components/link/CustomLink';

interface NavigationImageProps {
  item: HomePrismicSliceImage;
}

const NavigationImage: FC<NavigationImageProps> = ({ item }) => {
  return (
    <Styled.NavigationSimpleContainer>
      <Styled.NavigationSimpleTitle>{prismicH.asText(item.primary.title)}</Styled.NavigationSimpleTitle>

      <Styled.NavigationSimpleContent>
        {item.items.map((it, index) => (
          <Styled.NavigationSimpleItem key={index}>
            <div>
              {isFilled.image(it.image) ? <Image image={it.image} /> : null}

              <Styled.NavigationSimpleDescription>{it.description}</Styled.NavigationSimpleDescription>
            </div>

            <Styled.NavigationSimpleButton as={CustomLink} {...getLinkPropsFromPrismicLink(it.link)}>
              {it.label}
            </Styled.NavigationSimpleButton>
          </Styled.NavigationSimpleItem>
        ))}
      </Styled.NavigationSimpleContent>
    </Styled.NavigationSimpleContainer>
  );
};

export default NavigationImage;
