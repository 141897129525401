import styled from 'styled-components';
import { complementaryColor, fontSize, fontWeight, tertiaryColor } from '@styles/utils';
import { rgba } from 'polished';

export const ResellerWrapper = styled.div`
  margin-top: 64px;
  background-color: ${complementaryColor('white')};
`;

export const ResellerContainer = styled.div`
  margin: 0 auto;
  padding: 64px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1090px;

  @media screen and (max-width: 1230px) {
    padding: 50px 20px;
    flex-direction: column;
  }
`;

export const ResellerContent = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1230px) {
    padding-bottom: 20px;
  }
`;

export const ResellerTitle = styled.p`
  ${fontSize.h3};
  font-weight: ${fontWeight.bold};
  color: ${tertiaryColor(700)};
`;

export const ResellerDescription = styled.p`
  padding-top: 16px;
  ${fontSize.large};
  font-weight: ${fontWeight.normal};
  color: ${props => rgba(tertiaryColor(700)(props), 0.74)};
`;
