import React, { FC } from 'react';
import * as Styled from './NavigationSimple.styles';
import * as prismicH from '@prismicio/helpers';
import { HomePrismicSliceSimple } from '@modules/home/model';

interface NavigationSimpleProps {
  item: HomePrismicSliceSimple;
}

const NavigationSimple: FC<NavigationSimpleProps> = ({ item }) => {
  return (
    <Styled.NavigationSimpleContainer>
      <Styled.NavigationSimpleTitle>{prismicH.asText(item.primary.title)}</Styled.NavigationSimpleTitle>

      <Styled.NavigationSimpleContent>
        {item.items.map((it, index) => (
          <Styled.NavigationSimpleItem key={index}>
            <Styled.NavigationSimpleItemTitle>{it.title}</Styled.NavigationSimpleItemTitle>

            <Styled.NavigationSimpleItemList content={it.list} />
          </Styled.NavigationSimpleItem>
        ))}
      </Styled.NavigationSimpleContent>
    </Styled.NavigationSimpleContainer>
  );
};

export default NavigationSimple;
