import React, { FC } from 'react';
import * as Styled from './Navigation.styles';
import * as prismicT from '@prismicio/types';
import Image from 'next/image';
import CustomLink from '@shared/components/link/CustomLink';
import { getLinkPropsFromPrismicLink } from '@core/prismic';
import arrowIcon from '@assets/icons/arrow.svg';

interface NavigationProps {
  title?: string | null;
  description: string | null;
  link: prismicT.LinkField;
}

const Navigation: FC<NavigationProps> = ({ title, description, link }) => {
  return (
    <Styled.NavigationContainer>
      <div>
        {title ? <Styled.NavigationTitle>{title}</Styled.NavigationTitle> : null}

        <Styled.NavigationDescription>{description}</Styled.NavigationDescription>
      </div>

      {link.link_type !== 'Any' ? (
        <Styled.NavigationLink>
          <Styled.NavigationButton as={CustomLink} {...getLinkPropsFromPrismicLink(link)}>
            <Image src={arrowIcon} alt="Arrow" />
          </Styled.NavigationButton>
        </Styled.NavigationLink>
      ) : null}
    </Styled.NavigationContainer>
  );
};

export default Navigation;
