import React, { FC } from 'react';
import * as Styled from './Reseller.styles';
import * as prismicH from '@prismicio/helpers';
import { HomePrismicSliceReseller } from '@modules/home/model';
import { Button } from '@styles/shared/buttons';
import CustomLink from '@shared/components/link/CustomLink';
import { useAuthContext } from '@modules/auth/context';
import { renderOptional } from '@shared/utils/render';

interface ResellerProps {
  item: HomePrismicSliceReseller;
}

const Reseller: FC<ResellerProps> = ({ item }) => {
  const { profile } = useAuthContext();

  return renderOptional(
    profile,
    () => null,
    () => (
      <Styled.ResellerWrapper>
        <Styled.ResellerContainer>
          <Styled.ResellerContent>
            <Styled.ResellerTitle>{prismicH.asText(item.primary.title)}</Styled.ResellerTitle>

            <Styled.ResellerDescription>{item.primary.description}</Styled.ResellerDescription>
          </Styled.ResellerContent>

          <Button as={CustomLink} to="sign-in">
            {item.primary.label}
          </Button>
        </Styled.ResellerContainer>
      </Styled.ResellerWrapper>
    ),
  );
};

export default Reseller;
