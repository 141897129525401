import styled from 'styled-components';
import { fontSize, fontWeight, primaryColor, tertiaryColor } from '@styles/utils';

export const NavigationTextWrapper = styled.div`
  margin-top: 64px;
  background-color: ${primaryColor(100)};
`;

export const NavigationTextContainer = styled.div`
  margin: 0 auto;
  padding: 64px 0;
  width: 100%;
  max-width: 1090px;

  @media screen and (max-width: 1230px) {
    padding: 50px 20px;
  }
`;

export const NavigationTextTitle = styled.p`
  ${fontSize.h3};
  font-weight: ${fontWeight.bold};
  color: ${tertiaryColor(700)};
`;

export const NavigationTextContent = styled.div`
  padding-top: 18px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 29px;
  row-gap: 16px;

  @media screen and (max-width: 1230px) {
    grid-template-columns: 1fr;
  }
`;
